import { default as campaignsCues1l4U7fMeta } from "/app/pages/campaigns.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as listings1shni4HB7aMeta } from "/app/pages/listings.vue?macro=true";
import { default as paymentsF6kC2sgIAjMeta } from "/app/pages/payments.vue?macro=true";
import { default as reportsmaVQ6G3oUEMeta } from "/app/pages/reports.vue?macro=true";
import { default as unauthorizedUnTSWnoGh4Meta } from "/app/pages/unauthorized.vue?macro=true";
import { default as usersY73XUGL82qMeta } from "/app/pages/users.vue?macro=true";
import { default as analysisgZsel3qWTiMeta } from "/app/pages/valuation/analysis.vue?macro=true";
import { default as indexPJbeRV9l6OMeta } from "/app/pages/valuation/index.vue?macro=true";
import { default as _91id_93oOQofA6vRjMeta } from "/app/pages/valuation/requests/[id].vue?macro=true";
import { default as index1geTHGmHXUMeta } from "/app/pages/valuation/requests/index.vue?macro=true";
export default [
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/app/pages/campaigns.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "listings",
    path: "/listings",
    component: () => import("/app/pages/listings.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    component: () => import("/app/pages/payments.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/app/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    meta: unauthorizedUnTSWnoGh4Meta || {},
    component: () => import("/app/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/app/pages/users.vue").then(m => m.default || m)
  },
  {
    name: "valuation-analysis",
    path: "/valuation/analysis",
    meta: analysisgZsel3qWTiMeta || {},
    component: () => import("/app/pages/valuation/analysis.vue").then(m => m.default || m)
  },
  {
    name: "valuation",
    path: "/valuation",
    component: () => import("/app/pages/valuation/index.vue").then(m => m.default || m)
  },
  {
    name: "valuation-requests-id",
    path: "/valuation/requests/:id()",
    component: () => import("/app/pages/valuation/requests/[id].vue").then(m => m.default || m)
  },
  {
    name: "valuation-requests",
    path: "/valuation/requests",
    component: () => import("/app/pages/valuation/requests/index.vue").then(m => m.default || m)
  }
]