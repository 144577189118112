import { useUserRoles } from "~/composables/states"
import { Role, type UserRole } from "~/types/roles"

const allowedRoutes: Record<string, Role[]> = {
    '/': [Role.ADMIN, Role.LISTING_REVIEWER, Role.VALUATION_REVIEWER, Role.CAMPAIGNS_REVIEWER],
    '/listings': [Role.ADMIN, Role.LISTING_REVIEWER],
    '/valuation': [Role.ADMIN, Role.VALUATION_REVIEWER],
    // '/valuation/requests': [Role.ADMIN, Role.VALUATION_REVIEWER],
    '/valuation/analysis': [Role.ADMIN, Role.VALUATION_REVIEWER],
    '/campaigns': [Role.ADMIN, Role.CAMPAIGNS_REVIEWER],
    // '/payments': [Role.ADMIN],
    // '/reports': [Role.ADMIN],
    // '/users': [Role.ADMIN],
}

// TODO(rasbi):
// The middleware runs in both server and client side.
// Do we need that? Think about how to improve it.

const unauthorizeUrl = '/Unauthorized';

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userRoles = useUserRoles();

    // check if userRoles is already set
    if (userRoles.value.length > 0) {
        return;
    }

    // if debug (won't help in api)
    // // if (process.env.NODE_ENV === 'development') {
    // //     userRoles.value = [{ id: '1', name: Role.ADMIN}];
    // //     return;
    // // }
    // if (process.client && window.location.hostname === 'localhost') {
    //     userRoles.value = [{ id: '1', name: Role.ADMIN }];
    //     return;
    // }

    const { data, error } = await useFetch<UserRole[]>('/api/users/roles', {
        method: 'get',
    })

    if (error.value) {
        console.error(error.value)
    }

    const isUnauthorizedPath = to.path === unauthorizeUrl;

    if (error.value || !Array.isArray(data.value) || data.value.length === 0) {
        userRoles.value = [];

        if (isUnauthorizedPath) {
            return;
        }

        return navigateTo(unauthorizeUrl, { external: true });
    }

    userRoles.value = data.value;

    if (isUnauthorizedPath) {
        return;
    }

    if (!allowedRoutes[to.path]) {
        return navigateTo(unauthorizeUrl, { external: true });
    }

    const allowedRoles = allowedRoutes[to.path];
    for (const role of userRoles.value) {
        if (allowedRoles.includes(role.name as Role)) {
            return;
        }
    }

    return navigateTo(unauthorizeUrl, { external: true });
})