export default defineNuxtPlugin(() => {
    return {
        provide: {
            theme: {
                icons: {
                    sidebar: {
                        listing: 'tabler-layout-list',
                        users: 'tabler-users',
                        monetization: 'tabler-moneybag',
                        reports: 'tabler-report-analytics',
                    }
                }
            }
        }
    }
})