import { type PredictionUserInputs, PlotShape, PropertyType } from "../types/valuation"
import type { popup } from '@/types/popup'
import type { valuationRequest } from '@/types/valuationRequest'
import type { UserRole } from "~/types/roles"

export interface Toast {
    text: string
    type: 'success' | 'error'
}

export const useUserRoles = () => useState<UserRole[]>('userRoles', () => [])
export const useShowSidebar = () => useState<boolean>('showSidebar', () => false)
export const useShowListingsSidebar = () => useState<boolean>('showListingsSidebar', () => true)
export const usePredictionUserInputs = () => useCookie<PredictionUserInputs>('predictionInputs', {
    default() {
        return {
            landArea: 600,
            propertyType: PropertyType.LAND,
            plotShape: PlotShape.MIDDLE,
        }
    },
})

export const useListingsFiltersDateRange = () => useState<number>('listingsFiltersDateRange', () => 0)
export const useListingsFiltersStatus = () => useState<number>('listingsFiltersStatus', () => 0)
export const useListingsFiltersSearchInput = () => useState<string>('listingsFiltersSearchInput', () => '')

export const useToast = () => useState<Toast | null>('toast', () => null)

export const useRequest = () =>
    useState<valuationRequest>('valuationRequest', () => {
        return {
            requestId: 0,
            createdByUserId: 0,
            CreatedAt: new Date(),
            propertyType: "",
            propertyStage: "",
            propertyUsage: "",
            beneficiary: "",
            address: "",
            note: "---",
            fullName: "",
            phoneNumber: 0,
            Email: "",
            docList: [""],
            requeststatus: "",
            isPaid: true,
            issuedInvoice: 0
        };
    });

export const useValuationPopup = () => useState<popup>('popupContent', () => {
    return {
        title: "",
        content: "",
        show: false
    };
});