export enum Role {
    ADMIN = 'admin',
    LISTING_REVIEWER = 'listing_reviewer',
    VALUATION_REVIEWER = 'valuation_reviewer',
    CAMPAIGNS_REVIEWER = 'campaigns_reviewer'
}

export interface UserRole {
    id: string;
    name: string
}