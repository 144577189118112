export enum PropertyType {
    LAND = 4,
}

export enum PlotShape {
    MIDDLE = 5,
    OPEN_FROM_ONE_SIDE = 6,
    OPEN_FROM_TWO_SIDES = 7,
    CORNER = 8,
    OPEN_FROM_THREE_SIDES = 9,
    WIDE_FRONT = 10,
    SQUARE = 11,
}

export interface MonthPrice {
    date: string;
    prices: number[];
}

export interface PredictionUserInputs {
    landArea: number;
    propertyType: PropertyType;
    plotShape: PlotShape;
}

export interface Prediction {
    price_per_m_sq: number
    price: number;
}

export interface RelevantListing {
    id: number
    distance: number
}

export interface PredictionReq {
    x: number;
    y: number;
    date: string;
    land_area: number;
    property_type: PropertyType;
    plot_shape: PlotShape;
}

export interface SinglePredictionResp {
    result: Record<string, Prediction>;
}

export interface QuantilePredictionResp {
    result: Record<string, Prediction[]>;
    quantile: number[];
}

export interface RelevantListingsResp {
    result: RelevantListing[];
}